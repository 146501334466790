<template>
  <div>
    <!-- Filters -->
    <services-list-filters
      :gender.sync="gender"
      :familyStatus.sync="familyStatus"
      :city.sync="city"
      :town.sync="town"
      :neighborhood.sync="neighborhood"
      :street.sync="street"
      :weakness.sync="weakness"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :service-filter.sync="serviceFilter"
      :main-service.sync="mainService"
      :gender-options="genderOptions"
      :status-options="statusOptions"
    />
    <b-card no-body>
      <b-card-header class="pb-50"> </b-card-header>
      <!-- <b-card-body>
        <hr />
        <b-row>
          <b-col xl="2"> <label> العمر</label> </b-col>

          <b-col cols="" md="3" class="mb-md-0 mb-2">
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>من</h6>
              <b-form-input
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="min_age"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="" md="3" class="mb-md-0 mb-2">
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>إلى</h6>
              <b-form-input
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                v-model="max_age"
                class="w-100"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xl="2"> <label> تاريخ التسجيل</label> </b-col>
          <b-col md="3" class="mb-md-0 mb-2">
            <h6>من</h6>
            <flat-pickr
              v-model="startDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
          <b-col md="3" class="mb-md-0 mb-2">
            <h6>إلى</h6>
            <flat-pickr
              v-model="endDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
        </b-row>
      </b-card-body> -->
    </b-card>
    <!-- Table Container Card -->
  <b-card no-body>
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1"> احصائيات الاعمار </b-card-title>

          </div>
          <!--/ title and subtitle -->

          <!-- badge -->

          <!--/ badge -->
        </b-card-header>

        <b-card-body>

      <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="fetchBeneficiary"
        responsive
        :fields="tableColumnsBen"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column::items="fetchEXcel" User primary-key="id" -->

        <!-- Column: Role -->

        <!-- Column: Status -->

        <!-- Column: Status -->
        <template #cell(service_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.service_status)}`"
            class="text-capitalize"
          >
            {{ data.item.service_status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->

      </b-table>
          <!-- <vue-apex-charts
            type="line"
            height="400"
            :options="chartOptions"
            :series="chartOptions.series"
          /> -->
        </b-card-body>
         <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      </b-card>
      <b-card>
        <statistics-service-status/>
      </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
  BCardTitle
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import ServicesListFilters from "../services-beneficiary-list/ServicesListFilters.vue";
import usestatistics from "./usestatistics";
import { max } from "@validations";
import statistics from '../statistics-of-beneficiaries/statistics.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import servicesStoreModule from "../services-beneficiary-list/servicesStoreModule";
import { jsontoexcel } from "vue-table-to-excel";
import FamilyDetailsVue from "../families/family-beneficiaries/Family-Details.vue";
import VueApexCharts from 'vue-apexcharts'
import statisticsServiceStatus from './statisticsServiceStatus'
export default {
  setup() {
    const Services_APP_STORE_MODULE_NAME = "app-services";

    // Register module
    if (!store.hasModule(Services_APP_STORE_MODULE_NAME))
      store.registerModule(Services_APP_STORE_MODULE_NAME, servicesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Services_APP_STORE_MODULE_NAME))
        store.unregisterModule(Services_APP_STORE_MODULE_NAME);
    });


    const genderOptions = [
      { label: "ذكر", value: 1 },
      { label: "أنثى", value: 2 },
      { label: " غير محدد ", value: 0 },
    ];

    const statusOptions = [
      { label: 'بالأنتظار', value: 0 },
      { label: 'مفتوحة', value: 1 },
      { label: 'مغلقة', value: 2 },
      { label: 'حالة جديدة من قسم التسجيل ', value: 4 },
      { label: 'بانتظار الموافقة', value: 5 },
      { label: 'تم تحويلها', value: 6 },
    ]
    //  const serviceOptions = [
    //   { label: "Pending", value: 0 },
    //   { label: "Opened", value: 1 },
    //   { label: "Closed", value: 2 },

    // ];
    const dataFound = ref(FamilyDetailsVue);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());

    const {
      fetchBenServices,

      tableColumnsBen,
      fetchBeneficiary,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,
      ExcelDone,



      // Extra Filters
      gender,
      familyStatus,
      town,
      street,
      neighborhood,
      weakness,
      city,
      search,
      min_age,
      max_age,
      startDate,
      fileExcel,
      endDate,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      openStatus,
      changeStatus,

      succesStory,
      datatest,
      datastatistics,
      chartOptions,
    } = usestatistics();

    return {
      // Sidebar
      chartOptions,
      fetchBeneficiary,
      dataFound,
      datatest,
      ExcelDone,
      fetchBenServices,
changeStatus,

      openStatus,
      succesStory,
      tableColumnsBen,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      search,
      min_age,
      max_age,
      avatarText,
      serviceOptions: [],
      // UI

      genderOptions,

      statusOptions,

      // Extra Filters
      fileExcel,
      startDate,
      endDate,
      town,
      street,
      neighborhood,
      weakness,
      city,
      gender,
      familyStatus,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      max: maxDate,
    };
  },
  components: {
    statistics,
    BCardTitle,
    ServicesListFilters,
    flatPickr,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    VueApexCharts,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
statisticsServiceStatus,
    vSelect,
  },
  data() {
    return {
      role:'',



    };
  },

   created() {


    let userData = JSON.parse(localStorage.getItem("userData"));
    this.role = userData.roles[0].name;

   }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}

div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
