import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";
import axios from "@axios";

export default function usestatistics() {




  const refServiceListTable = ref(null);
  // Table Handlers
   const tableColumnsBen = [
    { key: "id", label: " الرقم",formatter: title,},
    { key: "first_name", label: "الاسم",formatter: title,},
    { key: "last_name", label: "النسبة",formatter: title,},
    { key: "birth_place", label: " مكان الولادة",formatter: title,},
    { key: "mother_name", label: " اسم الام",formatter: title,},
    { key: "educational_attainment", label: "الحالة التعليمية",formatter: title,},
    { key: "martial_status", label: "الحالة الاجتماعية",formatter: title,},



  ];
  const tableColumns = [
    { key: "id", label: " الرقم",formatter: title,},
    { key: "first_name", label: "الاسم",formatter: title,},
    { key: "last_name", label: "النسبة",formatter: title,},
    { key: "service_status", label: "الحالة",formatter: title,},

    {
      key: "created_by",
      label: "الموظف",
      formatter: title,

    },


    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const gender = ref(null);
  const familyStatus = ref(null);
  const city = ref(null);
  const town = ref(null);
  const street = ref(null)
  const neighborhood = ref(null)
  const weakness=ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const serviceFilter = ref(null);
  const mainService= ref(null);
  const min_age = ref(null);
  const search = ref(null);

  const max_age = ref(null);

  const datastatistics = ref([]);
  const ExcelDone=ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refServiceListTable.value
      ? refServiceListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refServiceListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      startDate,
      city,
      town,
      neighborhood,
      street,
      weakness,
      endDate,
      gender,
      familyStatus,
      serviceFilter,
      mainService,
      min_age,
      search,
      max_age,
      planFilter,
      statusFilter,

    ],
    () => {
        refetchData();
    }
  );




 const   chartOptions= {
  series: [
  {
    data: [],
  }],



  dataLabels: {
    enabled: false,
  },
  noData: {
    text: 'No Data...'
  },


  xaxis: {
    categories: ['0_4','5_7','8_9','10_11','12_14','15_17','18_19','20_24','25_29','30_34','35_39','40_44','45_49',
    '50_54','55_59','60_64','>65'
     ]

  },

}
const fetchBeneficiary = (ctx, callback) => {


  store
    .dispatch("app-services/fetchBeneficiary", {
      gender: gender.value,
      familyStatus:familyStatus.value,
      city: city.value,
      town: town.value,
      neighborhood: neighborhood.value,
      street:street.value,
      weakness:weakness.value,
      minDate: startDate.value,
      maxDate: endDate.value,
      service_status: statusFilter.value,
      service: serviceFilter.value,
      main_service:mainService.value,
      search:search.value,
      min_age: min_age.value,
      max_age: max_age.value,
      page_size: perPage.value,

      page: currentPage.value,
    })
    .then((response) => {
     //console.log("ugygy",response.data)


      const { data ,total} = response.data
      callback(data);
        totalUsers.value = total;





    })
    .catch((error) => {
        //console.log("error",error)
       Vue.swal({
            title: "حدثت مشكلة في استرجاع البيانات",
    icon: "error",

      confirmButtonText: "موافق",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    });
};
  const fetchstatistics= async()=>{
      //console.log("Fhfhfh")
      chartOptions.series[0].data=[]
await  store
  .dispatch("app-services/fetchstatistics", {
    gender: gender.value,
    familyStatus:familyStatus.value,
    city: city.value,
    town: town.value,
    neighborhood: neighborhood.value,
    street:street.value,
    weakness:weakness.value,
    minDate: startDate.value,
    maxDate: endDate.value,
    service_status: statusFilter.value,
    service: serviceFilter.value,
    main_service:mainService.value,
    search:search.value,
    min_age: min_age.value,
    max_age: max_age.value,


    page: currentPage.value,
  })
  .then((response) => {


    // const { data ,total} = response.data.data;
    window.dispatchEvent(new Event('resize'))

    response.data.data[0].stats.forEach(el=>{

      chartOptions.series[0].data.push(el.value)
    })





  })
    .catch(() => {

    });

}





  return {
    fetchstatistics,
    datastatistics,
    chartOptions,
    tableColumns,
    tableColumnsBen,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refServiceListTable,
    refetchData,
    fetchBeneficiary,
    gender,
    familyStatus,
    city,
    town,
    street,
    neighborhood,
    weakness,
    startDate,
    endDate,
    planFilter,
    statusFilter,
   mainService,
   serviceFilter,
   search,
    min_age,

    max_age,
  };
}
