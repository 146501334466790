<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title=" معلومات  العائلة ">
     
          <b-row>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label> طبيعة العائلة</label>
                <h5 class="text-capitalize mb-75">
                  {{ FamilyDetails.family_status.name }}
                </h5>

              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label> تاريخ النزوح</label>
                <h5 class="text-capitalize mb-75">
                  {{ FamilyDetails.displacement_date }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label> تاريخ التسجيل</label>
                <h5 class="text-capitalize mb-75">
                  {{ FamilyDetails.registration_date }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label> طريقة التعرف على المركز</label>
                <h5 class="text-capitalize mb-75">
                  {{ FamilyDetails.recognized_by_method.name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="note">
                <label> الملاحظات </label>

                <h5 class="text-capitalize mb-75">
                  {{ FamilyDetails.notes }}
                </h5>
              </b-form-group>
            </b-col>

          </b-row>

        </b-card-code>
        <b-card-code title="بطاقة العائلة">
          <b-row>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  رقم البطاقة</label>
                <h5 class="text-capitalize mb-75">
                  {{ FmailyCard.number }}
                </h5>

              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  صاحب البطاقة</label>
                <h5 class="text-capitalize mb-75">
                  {{ FmailyCard.owner.first_name }}
                </h5>

              </b-form-group>
            </b-col>

            <b-col
              v-if="FmailyCard.department"
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  القسم الصادرة عنه البطاقة</label>
                <h5 class="text-capitalize mb-75">
                  {{ FmailyCard.department.name }}
                </h5>

              </b-form-group>
            </b-col>

            <b-col
              v-if="FmailyCard.issuance_reason"
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  سبب الاستصدار</label>
                <h5 class="text-capitalize mb-75">
                  {{ FmailyCard.issuance_reason.name }}
                </h5>

              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  تاريخ الأصدار</label>
                <h5 class="text-capitalize mb-75">
                  {{ FmailyCard.print_date }}
                </h5>

              </b-form-group>
            </b-col>
          </b-row>
        </b-card-code>
        <b-card-code
          v-if="FamilyCurrentAddress"
          title=" مكان الأقامة الحالي"
        >
          <title />

          <b-row>
            <!-- credit card -->
            <b-col
              md="6"
              xl="3"
              v-if="FamilyCurrentAddress.country.name "
            >

              <label> البلد</label>
              <h5 class="text-capitalize mb-75">

                {{ FamilyCurrentAddress.country.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
              v-if="FamilyCurrentAddress.governorate.name "
            >
              <label> المحافظة</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyCurrentAddress.governorate.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
              v-if="FamilyCurrentAddress.city.name "
            >

              <label> المنطقة</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyCurrentAddress.city.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
              v-if="FamilyCurrentAddress.town.name "
            >

              <label> الناحية</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyCurrentAddress.town.name }}
              </h5>
            </b-col>

            <b-col
              md="6"
              xl="3"
              v-if="FamilyCurrentAddress.residence_type.name "
            >

              <label> نوع السكن</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyCurrentAddress.residence_type.name }}
              </h5>
            </b-col>
            <b-col
              v-if="FamilyDetails.residence_notes"
              md="6"
              xl="3"
            >

              <label> تفاصيل العنوان</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyCurrentAddress.residence_notes }}
              </h5>
            </b-col>

          </b-row>
        </b-card-code>
        <b-card-code
          v-if="FamilyOrginalAddress"
          title=" مكان الأقامة الاصلي"
        >
          <title />

          <b-row>
            <!-- credit card -->
            <b-col
              md="6"
              xl="3"
            >

              <label> البلد</label>
              <h5 class="text-capitalize mb-75">

                {{ FamilyOrginalAddress.country.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >
              <label> المحافظة</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyOrginalAddress.governorate.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >

              <label> المنطقة</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyOrginalAddress.city.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >

              <label> الناحية</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyOrginalAddress.town.name }}
              </h5>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >

              <label> نوع السكن</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyOrginalAddress.residence_type.name }}
              </h5>
            </b-col>
            <b-col
              v-if="FamilyOrginalAddress.residence_notes"
              md="6"
              xl="3"
            >

              <label> تفاصيل العنوان</label>
              <h5 class="text-capitalize mb-75">
                {{ FamilyOrginalAddress.residence_notes }}
              </h5>
            </b-col>

          </b-row>
        </b-card-code>
        <b-card-code title=" معلومات الاتصال">
          <title />
          <!-- {{FamilyDetails.contacts}} -->
          <b-row v-for="contact in FamilyDetails.contacts">
            <!-- credit card -->
            <b-col
              md="6"
              xl="3"
            >

              <label> الرقم</label>
              <h5 class="text-capitalize mb-75">

                {{ contact.number }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >
              <label> نوع وسيلة الاتصال</label>
              <h5 class="text-capitalize mb-75">
                {{ contact.contact_type.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >

              <label> الملاحظة</label>
              <h5 class="text-capitalize mb-75">
                {{ contact.notes }}
              </h5>
            </b-col>

          </b-row>
        </b-card-code>
        <b-card-code title=" الوثائق">

          <!-- {{FamilyDetails.contacts}} -->
          <b-row v-for="document in FamilyDetails.documents">
            <!-- credit card -->
            <b-col
              md="6"
              xl="3"
            >

              <label> الرقم</label>
              <h5 class="text-capitalize mb-75">

                {{ document.number }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >
              <label> نوع  الوثيقة</label>
              <h5 class="text-capitalize mb-75">
                {{ document.document_type.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
            >

              <label> الملاحظة</label>
              <h5 class="text-capitalize mb-75">
                {{ document.notes }}
              </h5>
            </b-col>

          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import 'cleave.js/dist/addons/cleave-phone.us'

import flatPickr from 'vue-flatpickr-component'
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import 'flatpickr/dist/flatpickr.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BForm,
    BCardCode,

    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,

    BRow,

    BCol,
  },
  //   props: {
  //     FamilyDetails: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  data() {
    return {
      FamilyDetails: {

        family_status: {
          name: '',
        },
        recognized_by_method: {
          name: '',
        },
        registration_date: '',
        displacement_date: '',

      },
      FmailyCard: {
        owner: {
          first_name: '',
        },
      },
      FamilyCurrentAddress: {},
      FamilyOrginalAddress: {},
    }
  },
  created() {
    this.getFamilyInfo()
  },
  methods: {
    getFamilyInfo() {
      const url = `/api/v1/families/${this.$route.params.id}`

      this.$http.get(url).then(res => {
        // console.log(res)
        this.FamilyDetails = res.data.data
        this.FmailyCard = res.data.data.card
        this.FamilyCurrentAddress = res.data.data.current_address
        this.FamilyOrginalAddress = res.data.data.original_address
      })
    },
  },
}
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-size: 12px;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
